<template>
    <div class="svg-wrapper locker-instructions-coinlock-1">
        <!-- Generator: Adobe Illustrator 27.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg
            version="1.1"
            id="Laag_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 151.66 67.46"
            style="enable-background: new 0 0 151.66 67.46"
            xml:space="preserve"
        >
            <g>
                <g>
                    <defs>
                        <path
                            id="SVGID_00000180351574943465272680000004912959970902434489_"
                            d="M141.93,60.44H9.73c-1.89,0-3.42-1.53-3.42-3.42V10.44
				c0-1.89,1.53-3.42,3.42-3.42h132.2c1.89,0,3.42,1.53,3.42,3.42v46.57C145.35,58.91,143.82,60.44,141.93,60.44z"
                        />
                    </defs>
                    <clipPath id="SVGID_00000120552857307948244390000003643821646842456713_">
                        <use xlink:href="#SVGID_00000180351574943465272680000004912959970902434489_" style="overflow: visible" />
                    </clipPath>

                    <use
                        xlink:href="#SVGID_00000180351574943465272680000004912959970902434489_"
                        style="overflow: visible; fill: none; stroke: #505754; stroke-width: 3.5307; stroke-miterlimit: 10"
                    />
                </g>
                <g>
                    <path
                        class="st10"
                        d="M30.11,27.58l-2.03,2.22c-0.23-0.29-0.54-0.51-0.95-0.67s-0.8-0.23-1.2-0.23c-0.49,0-0.95,0.12-1.37,0.35
			s-0.63,0.58-0.63,1.04c0,0.33,0.11,0.6,0.34,0.83c0.23,0.22,0.52,0.4,0.88,0.53s0.76,0.26,1.21,0.38
			c0.44,0.12,0.89,0.27,1.33,0.45c0.44,0.18,0.85,0.4,1.21,0.66c0.36,0.26,0.66,0.62,0.88,1.09s0.34,1.02,0.34,1.65
			c0,1.43-0.49,2.53-1.48,3.29c-0.99,0.76-2.22,1.14-3.71,1.14c-1.8,0-3.31-0.56-4.52-1.69l2.11-2.32c0.3,0.39,0.69,0.7,1.15,0.93
			c0.46,0.23,0.94,0.34,1.43,0.34c0.53,0,1.01-0.12,1.42-0.37s0.63-0.59,0.63-1.04c0-0.38-0.16-0.7-0.47-0.97
			c-0.31-0.27-0.7-0.46-1.17-0.59c-0.47-0.13-0.98-0.29-1.52-0.49c-0.54-0.2-1.05-0.42-1.52-0.67c-0.47-0.24-0.86-0.61-1.17-1.11
			c-0.31-0.5-0.47-1.1-0.47-1.8c0-1.37,0.5-2.44,1.51-3.21c1.01-0.77,2.21-1.16,3.62-1.16C27.67,26.17,29.04,26.64,30.11,27.58z"
                    />
                    <path class="st10" d="M32.29,39.97V26.52h2.96v13.45H32.29z" />
                    <path class="st10" d="M37.96,39.97V26.52h4.03l5.72,9.35h0.04v-9.35h2.96v13.45h-3.88l-5.87-9.58h-0.04v9.58H37.96z" />
                    <path
                        class="st10"
                        d="M65.62,31.99v7.03c-1.65,0.86-3.53,1.29-5.64,1.29c-2.13,0-3.87-0.65-5.22-1.94c-1.36-1.29-2.03-3-2.03-5.13
			s0.68-3.84,2.03-5.13c1.35-1.29,3.1-1.94,5.22-1.94c2.33,0,4.12,0.58,5.36,1.73l-2.09,2.28c-0.81-0.85-1.9-1.27-3.27-1.27
			c-1.25,0-2.26,0.41-3.03,1.22c-0.77,0.81-1.15,1.85-1.15,3.12s0.38,2.31,1.15,3.12c0.77,0.81,1.78,1.22,3.03,1.22
			c1.06,0,2-0.22,2.79-0.67v-2.19h-2.41v-2.74H65.62z"
                    />
                    <path class="st10" d="M68.34,39.97V26.52h2.96v10.72h5.49v2.74H68.34z" />
                    <path class="st10" d="M78.54,39.97V26.52h9.14v2.74H81.5v2.51h5.83v2.74H81.5v2.74h6.52v2.74H78.54z" />
                    <path
                        class="st10"
                        d="M107.36,26.52v8.27c0,1.65-0.49,2.98-1.47,4s-2.38,1.53-4.19,1.53s-3.21-0.51-4.2-1.53
			c-0.99-1.02-1.48-2.35-1.48-4v-8.27h2.96v8.15c0,0.84,0.26,1.53,0.77,2.08c0.51,0.55,1.16,0.83,1.95,0.83
			c0.77,0,1.42-0.28,1.93-0.84c0.51-0.56,0.77-1.25,0.77-2.07v-8.15H107.36z"
                    />
                    <path
                        class="st10"
                        d="M118.78,27.58l-2.03,2.22c-0.23-0.29-0.54-0.51-0.95-0.67s-0.8-0.23-1.2-0.23c-0.49,0-0.95,0.12-1.37,0.35
			s-0.63,0.58-0.63,1.04c0,0.33,0.11,0.6,0.34,0.83c0.23,0.22,0.52,0.4,0.88,0.53s0.76,0.26,1.21,0.38
			c0.44,0.12,0.89,0.27,1.33,0.45c0.44,0.18,0.85,0.4,1.21,0.66c0.36,0.26,0.66,0.62,0.88,1.09s0.34,1.02,0.34,1.65
			c0,1.43-0.49,2.53-1.48,3.29c-0.99,0.76-2.22,1.14-3.71,1.14c-1.8,0-3.31-0.56-4.52-1.69l2.11-2.32c0.3,0.39,0.69,0.7,1.15,0.93
			c0.46,0.23,0.94,0.34,1.43,0.34c0.53,0,1.01-0.12,1.42-0.37s0.63-0.59,0.63-1.04c0-0.38-0.16-0.7-0.47-0.97
			c-0.31-0.27-0.7-0.46-1.17-0.59c-0.47-0.13-0.98-0.29-1.52-0.49c-0.54-0.2-1.05-0.42-1.52-0.67c-0.47-0.24-0.86-0.61-1.17-1.11
			c-0.31-0.5-0.47-1.1-0.47-1.8c0-1.37,0.5-2.44,1.51-3.21c1.01-0.77,2.21-1.16,3.62-1.16C116.34,26.17,117.72,26.64,118.78,27.58z"
                    />
                    <path class="st10" d="M121.12,39.97V26.52h9.14v2.74h-6.18v2.51h5.83v2.74h-5.83v2.74h6.52v2.74H121.12z" />
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'LockerInstructionsCoinlock_1',
};
</script>
