<template>
    <div class="svg-wrapper locker-instructions-1">
        <svg
            version="1.1"
            id="Laag_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 154.05 65.02"
            style="enable-background: new 0 0 154.05 65.02"
            xml:space="preserve"
        >
            <g>
                <defs>
                    <path
                        id="SVGID_1_"
                        d="M143.13,59.22H10.92c-1.89,0-3.42-1.53-3.42-3.42V9.22c0-1.89,1.53-3.42,3.42-3.42h132.2
			c1.89,0,3.42,1.53,3.42,3.42V55.8C146.54,57.69,145.01,59.22,143.13,59.22z"
                    />
                </defs>
                <clipPath id="SVGID_00000176039270157724383780000002376177056038353312_">
                    <use xlink:href="#SVGID_1_" style="overflow: visible" />
                </clipPath>
                <g style="clip-path: url(#SVGID_00000176039270157724383780000002376177056038353312_)">
                    <rect x="53.24" y="20.8" class="st2" width="23.43" height="23.43" />
                    <g>
                        <g>
                            <path
                                class="st1"
                                d="M74.27,68.87H9.2c-2.07,0-3.76-1.69-3.76-3.76V0.04c0-2.07,1.69-3.76,3.76-3.76h65.07
					c2.07,0,3.76,1.69,3.76,3.76v65.07C78.03,67.18,76.35,68.87,74.27,68.87z M9.2-1.41c-0.8,0-1.45,0.65-1.45,1.45v65.07
					c0,0.8,0.65,1.45,1.45,1.45h65.07c0.8,0,1.45-0.65,1.45-1.45V0.04c0-0.8-0.65-1.45-1.45-1.45H9.2z"
                            />
                            <g>
                                <path
                                    class="st1"
                                    d="M30.03,68.87c-0.64,0-1.16-0.52-1.16-1.16V-2.56c0-0.64,0.52-1.16,1.16-1.16s1.16,0.52,1.16,1.16v70.28
						C31.18,68.35,30.66,68.87,30.03,68.87z"
                                />
                                <path
                                    class="st1"
                                    d="M53.45,68.87c-0.64,0-1.16-0.52-1.16-1.16V-2.56c0-0.64,0.52-1.16,1.16-1.16c0.64,0,1.16,0.52,1.16,1.16
						v70.28C54.61,68.35,54.09,68.87,53.45,68.87z"
                                />
                            </g>
                            <g>
                                <path
                                    class="st1"
                                    d="M76.88,22.02H6.6c-0.64,0-1.16-0.52-1.16-1.16c0-0.64,0.52-1.16,1.16-1.16h70.28
						c0.64,0,1.16,0.52,1.16,1.16C78.03,21.5,77.52,22.02,76.88,22.02z"
                                />
                                <path
                                    class="st1"
                                    d="M76.88,45.44H6.6c-0.64,0-1.16-0.52-1.16-1.16c0-0.64,0.52-1.16,1.16-1.16h70.28
						c0.64,0,1.16,0.52,1.16,1.16C78.03,44.93,77.52,45.44,76.88,45.44z"
                                />
                            </g>
                            <g>
                                <path
                                    class="st1"
                                    d="M11.81,35.03c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16s1.16,0.52,1.16,1.16v2.6
						C12.96,34.51,12.45,35.03,11.81,35.03z"
                                />
                                <path
                                    class="st1"
                                    d="M35.23,35.03c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16c0.64,0,1.16,0.52,1.16,1.16
						v2.6C36.39,34.51,35.87,35.03,35.23,35.03z"
                                />
                                <path
                                    class="st1"
                                    d="M58.66,35.03c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16c0.64,0,1.16,0.52,1.16,1.16
						v2.6C59.81,34.51,59.3,35.03,58.66,35.03z"
                                />
                            </g>
                            <g>
                                <path
                                    class="st1"
                                    d="M11.81,11.61c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16s1.16,0.52,1.16,1.16v2.6
						C12.96,11.09,12.45,11.61,11.81,11.61z"
                                />
                                <path
                                    class="st1"
                                    d="M35.23,11.61c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16c0.64,0,1.16,0.52,1.16,1.16
						v2.6C36.39,11.09,35.87,11.61,35.23,11.61z"
                                />
                                <path
                                    class="st1"
                                    d="M58.66,11.61c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16c0.64,0,1.16,0.52,1.16,1.16
						v2.6C59.81,11.09,59.3,11.61,58.66,11.61z"
                                />
                            </g>
                            <g>
                                <path
                                    class="st1"
                                    d="M11.81,58.46c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16s1.16,0.52,1.16,1.16v2.6
						C12.96,57.94,12.45,58.46,11.81,58.46z"
                                />
                                <path
                                    class="st1"
                                    d="M35.23,58.46c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16c0.64,0,1.16,0.52,1.16,1.16
						v2.6C36.39,57.94,35.87,58.46,35.23,58.46z"
                                />
                                <path
                                    class="st1"
                                    d="M58.66,58.46c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16c0.64,0,1.16,0.52,1.16,1.16
						v2.6C59.81,57.94,59.3,58.46,58.66,58.46z"
                                />
                            </g>
                        </g>
                    </g>
                </g>
                <g style="clip-path: url(#SVGID_00000176039270157724383780000002376177056038353312_)">
                    <rect x="100.3" y="20.86" class="st0" width="23.43" height="23.43" />
                    <g>
                        <g>
                            <path
                                class="st1"
                                d="M144.55,68.87H79.48c-2.07,0-3.76-1.69-3.76-3.76V0.04c0-2.07,1.69-3.76,3.76-3.76h65.07
					c2.07,0,3.76,1.69,3.76,3.76v65.07C148.31,67.18,146.62,68.87,144.55,68.87z M79.48-1.41c-0.8,0-1.45,0.65-1.45,1.45v65.07
					c0,0.8,0.65,1.45,1.45,1.45h65.07c0.8,0,1.45-0.65,1.45-1.45V0.04c0-0.8-0.65-1.45-1.45-1.45H79.48z"
                            />
                            <g>
                                <path
                                    class="st1"
                                    d="M100.3,68.87c-0.64,0-1.16-0.52-1.16-1.16V-2.56c0-0.64,0.52-1.16,1.16-1.16s1.16,0.52,1.16,1.16v70.28
						C101.46,68.35,100.94,68.87,100.3,68.87z"
                                />
                                <path
                                    class="st1"
                                    d="M123.73,68.87c-0.64,0-1.16-0.52-1.16-1.16V-2.56c0-0.64,0.52-1.16,1.16-1.16s1.16,0.52,1.16,1.16v70.28
						C124.88,68.35,124.37,68.87,123.73,68.87z"
                                />
                            </g>
                            <g>
                                <path
                                    class="st1"
                                    d="M147.15,22.02H76.88c-0.64,0-1.16-0.52-1.16-1.16c0-0.64,0.52-1.16,1.16-1.16h70.28
						c0.64,0,1.16,0.52,1.16,1.16C148.31,21.5,147.79,22.02,147.15,22.02z"
                                />
                                <path
                                    class="st1"
                                    d="M147.15,45.44H76.88c-0.64,0-1.16-0.52-1.16-1.16c0-0.64,0.52-1.16,1.16-1.16h70.28
						c0.64,0,1.16,0.52,1.16,1.16C148.31,44.93,147.79,45.44,147.15,45.44z"
                                />
                            </g>
                            <g>
                                <path
                                    class="st1"
                                    d="M82.08,35.03c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16s1.16,0.52,1.16,1.16v2.6
						C83.24,34.51,82.72,35.03,82.08,35.03z"
                                />
                                <path
                                    class="st1"
                                    d="M105.51,35.03c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16s1.16,0.52,1.16,1.16v2.6
						C106.66,34.51,106.15,35.03,105.51,35.03z"
                                />
                                <path
                                    class="st1"
                                    d="M128.93,35.03c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16s1.16,0.52,1.16,1.16v2.6
						C130.09,34.51,129.57,35.03,128.93,35.03z"
                                />
                            </g>
                            <g>
                                <path
                                    class="st1"
                                    d="M82.08,11.61c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16s1.16,0.52,1.16,1.16v2.6
						C83.24,11.09,82.72,11.61,82.08,11.61z"
                                />
                                <path
                                    class="st1"
                                    d="M105.51,11.61c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16s1.16,0.52,1.16,1.16v2.6
						C106.66,11.09,106.15,11.61,105.51,11.61z"
                                />
                                <path
                                    class="st1"
                                    d="M128.93,11.61c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16s1.16,0.52,1.16,1.16v2.6
						C130.09,11.09,129.57,11.61,128.93,11.61z"
                                />
                            </g>
                            <g>
                                <path
                                    class="st1"
                                    d="M82.08,58.46c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16s1.16,0.52,1.16,1.16v2.6
						C83.24,57.94,82.72,58.46,82.08,58.46z"
                                />
                                <path
                                    class="st1"
                                    d="M105.51,58.46c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16s1.16,0.52,1.16,1.16v2.6
						C106.66,57.94,106.15,58.46,105.51,58.46z"
                                />
                                <path
                                    class="st1"
                                    d="M128.93,58.46c-0.64,0-1.16-0.52-1.16-1.16v-2.6c0-0.64,0.52-1.16,1.16-1.16s1.16,0.52,1.16,1.16v2.6
						C130.09,57.94,129.57,58.46,128.93,58.46z"
                                />
                            </g>
                        </g>
                    </g>
                </g>
                <use xlink:href="#SVGID_1_" class="st19" style="overflow: visible; fill: none; stroke-width: 3.5307; stroke-miterlimit: 10" />
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'LockerInstructions_1',
}
</script>