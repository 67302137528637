<template>
    <div v-if="loading" class="flex justify-center mt-32">
        <svg class="animate-spin ml-3 h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    </div>

    <template v-else-if="transaction.attributes.method === 'elockers'">
        <locker-confirmation :transaction="transaction" />
    </template>

    <div class="m-2 payment-success" v-else>
        <div class="content-container radius-anchor max-w-xl mx-auto" v-if="transaction">

            <div v-if="transaction.attributes.status == 'completed'">
                <div class="text-center">
                    <h2 class="text-3xl font-bold mb-4">{{ $t('confirmation.success.title') }}</h2>
                </div>

                <div v-if="transaction.attributes.method == 'reservation'" class="content-container checkout-box radius-anchor">
                    <div>
                        <div class="mt-5 font-bold">
                            <!-- <h2 class="font-bold text-xl text-center">{{ $t('reservation.confirmation.title') }}</h2> -->
                            <p class="mt-3 text-sm">{{ $t('reservation.confirmation.description') }}</p>
                        </div>

                        <div class="mt-2 grid grid-cols-2">
                            <div class="col-span-1 mr-2">{{ $t('reservation.confirmation.name') }}:</div>
                            <div class="col-span-1 ml-2">
                                <!-- {{ form.name }} -->
                            </div>
                        </div>

                        <div class="grid grid-cols-2">
                            <div class="col-span-1 mr-2">{{ $t('reservation.confirmation.date') }}:</div>
                            <div class="col-span-1 ml-2">
                                <!-- {{ form.date }} -->
                            </div>
                        </div>

                        <div class="grid grid-cols-2">
                            <div class="col-span-1 mr-2">{{ $t('reservation.confirmation.time') }}:</div>
                            <div class="col-span-1 ml-2">
                                <!-- {{ processedTimeslot(form.timeslot) }} -->
                            </div>
                        </div>

                        <div class="grid grid-cols-2">
                            <div class="col-span-1 mr-2">{{ $t('reservation.confirmation.quantity') }}:</div>
                            <div class="col-span-1 ml-2">
                                <!-- {{ form.quantity }} -->
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="transaction.attributes.mode == 'quickpickup'"
                     class="highlighted-box radius-anchor">
                    <div class="">
                        {{ $t('confirmation.pickup.description') }}
                    </div>
                    <router-link
                        :to="{ name: 'transaction.quickpickup', params: {location_slug: location_slug, transaction_id: transaction.id}}"
                        @click="orderAgainRedirect()"
                        class="inline-flex justify-center items-center mt-3 px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                        {{ $t('confirmation.pickup.button') }}
                    </router-link>
                </div>
                <!-- <div v-if="transaction.attributes.mode == 'qrcode'"> -->
                <div v-if="showQrCode === true">
                    <div class="flex justify-center">
                        <vue-qrcode :value="transaction.id" />
                    </div>
                    <div class="flex justify-center">
                        {{ transaction.attributes.shortcode }}
                    </div>
                </div>

                <div v-if="confirmation_text" class="confirmation-text-box highlighted-box highlighted-box-secondary">
                    <div class="text-sm" v-html="confirmation_text" />
                </div>

                <div class="confirmation-locker-list">
                    <div class="border radius-anchor" v-for="(ticket, index) in transaction.relationships.tickets"
                         v-bind:key="index">
                        <ticket :ticket="ticket" />
                    </div>
                </div>

                <div class="py-5">

                    <dl v-if="(['delivery', 'takeaway']).includes(this.transaction.attributes.method)" class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.method_' + transaction.attributes.method) }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                <span class="font-medium">{{ transaction.attributes.method_time }}</span> - {{ transaction.attributes.method_date }}
                            </dd>
                        </div>
                    </dl>

                    <dl v-if="transaction.attributes.account_id" class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.account') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.relationships.account.attributes.name }}
                            </dd>
                        </div>
                    </dl>

                    <dl class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.transactionnumber') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.attributes.transactionnumber }} <span
                                class="">({{ transaction.attributes.shortcode }})</span>
                            </dd>
                        </div>
                    </dl>


                    <dl v-if="transaction.relationships.table" class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.table') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.relationships.table.attributes.name }}
                            </dd>
                        </div>
                    </dl>
                    <!-- <dl class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                Status
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.attributes.status }}
                            </dd>
                        </div>
                    </dl> -->

                    <dl v-if="transaction.attributes.customer_email" class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.email') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.attributes.customer_email }}
                            </dd>
                        </div>
                    </dl>

                    <dl v-if="transaction.attributes.customer_phone_number" class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.phonenumber') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.attributes.customer_phone_number }}
                            </dd>
                        </div>
                    </dl>

                    <dl v-if="transaction.attributes.customer_name" class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.name') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.attributes.customer_name }}
                            </dd>
                        </div>
                    </dl>

                    <dl v-if="transaction.attributes.customer_streetname" class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.address') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                <div
                                    v-if="transaction.attributes.customer_streetname || transaction.attributes.customer_housenumber">
                                    {{ transaction.attributes.customer_streetname }}
                                    {{ transaction.attributes.customer_housenumber }}
                                </div>
                                <div
                                    v-if="transaction.attributes.customer_zipcode || transaction.attributes.customer_city">
                                    {{ transaction.attributes.customer_zipcode }}
                                    {{ transaction.attributes.customer_city }}
                                </div>
                            </dd>
                        </div>
                    </dl>

                    <dl v-if="transaction.attributes.notes" class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.notes') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.attributes.notes }}
                            </dd>
                        </div>
                    </dl>

                    <dl class="mb-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div class="sm:col-span-1">
                            <dt class="font-bold text-sm font-medium">
                                {{ $t('fields.ordered_at') }}
                            </dt>
                            <dd class="mt-1 text-sm">
                                {{ transaction.attributes.timestamp }}
                            </dd>
                        </div>
                    </dl>
                </div>

                <div class="py-5">

                    <table class="mb-5 min-w-full divide-y divide-gray-200">
                        <thead>
                            <tr>
                                <th class="sm:px-6 sm:py-3 py-1 border-b border-color text-left text-xs leading-4 font-bold uppercase tracking-wider">
                                    {{ $t('fields.quantity') }}
                                </th>
                                <th class="sm:px-6 sm:py-3 py-1 border-b border-color text-left text-xs leading-4 font-bold uppercase tracking-wider">
                                    {{ $t('fields.article') }}
                                </th>
                                <th class="sm:px-6 sm:py-3 py-1 border-b border-color text-right text-xs leading-4 font-bold uppercase tracking-wider">
                                    {{ $t('fields.subtotal') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200">
                            <tr v-for="(line, index) in transaction.relationships.lines" v-bind:key="index">
                                <td class="sm:px-6 sm:py-2 py-1 align-top whitespace-no-wrap border-b border-color text-sm leading-5 font-medium">
                                    {{ Math.round(line.attributes.quantity) }}
                                </td>
                                <td class="sm:px-6 sm:py-2 py-1 align-top whitespace-no-wrap border-b border-color text-sm leading-5">
                                    <div :class="{'pl-3 italic': line.attributes.addon == true}">
                                        {{ line.attributes.description }}
                                    </div>
                                    <div class="pl-3 italic"
                                         v-for="(value, description) in line.attributes.kitchen_groceries" :key="value">
                                        {{ description }}: {{ value }}
                                    </div>
                                </td>
                                <td class="sm:px-6 sm:py-2 py-1 align-top whitespace-no-wrap border-b border-color text-sm leading-5 text-right">
                                    <!-- <span v-if="line.attributes.total != 0"> -->
                                        {{ $n(line.attributes.total, currency) }}
                                    <!-- </span> -->
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="2"
                                    class="sm:px-6 sm:py-2 py-1 whitespace-no-wrap border-t-2 border-color text-sm font-bold leading-5 font-medium text-main text-right">
                                    {{ $t('fields.total') }}
                                </td>
                                <td class="sm:px-6 sm:py-2 py-1 whitespace-no-wrap border-t-2 border-color text-sm text-right font-bold leading-5 font-medium text-right">
                                    {{ $n(transaction.attributes.total, currency) }}
                                </td>
                            </tr>
                            <tr v-for="(taxes, index) in transaction.relationships.taxes" v-bind:key="index">
                                <td v-if="parseInt(index) > 0" class="sm:px-6 whitespace-no-wrap text-xs leading-5 font-medium text-main text-right"
                                    colspan="2">{{ index }} belasting:
                                </td>
                                <td v-if="parseInt(index) > 0" class="sm:px-6 whitespace-no-wrap text-xs leading-5 font-medium text-main text-right">
                                    {{ $n(taxes, currency) }}
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                    <table class="min-w-full divide-y divide-gray-200" v-if="transaction.relationships.payments.length > 0">
                        <thead>
                            <tr>
                                <th class="sm:px-6 sm:py-3 py-1 border-b border-color text-left text-xs leading-4 font-bold uppercase tracking-wider">
                                    {{ $t('fields.paymenttype') }}
                                </th>
                                <th class="sm:px-6 sm:py-3 py-1 border-b border-color text-right text-xs leading-4 font-bold uppercase tracking-wider">
                                    {{ $t('fields.amount') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200">
                            <tr v-for="(payment, index) in transaction.relationships.payments" v-bind:key="index">
                                <td class="sm:px-6 sm:py-2 py-1 align-top whitespace-no-wrap border-b border-color text-sm leading-5 font-medium">
                                    {{ payment.attributes.paymenttype }}
                                </td>
                                <td class="sm:px-6 sm:py-2 py-1 align-top whitespace-no-wrap border-b border-color text-sm leading-5 text-right">
                                    {{ $n(payment.attributes.amount, currency) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="flex items-center justify-center pt-2 rounded-b order-again">
                    <span class="inline-flex rounded-sm shadow-sm">

                        <button @click="orderAgainRedirect()"
                                class="inline-flex justify-center items-center mt-3 px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                                {{ $t('confirmation.success.place_another_order') }}
                        </button>
                    </span>
                </div>
            </div>

            <!-- open/pending -->
            <div v-else-if="transaction.attributes.status == 'open'">
                <div class="text-center pt-5">
                    <h2 class="text-3xl font-bold">{{ $t('confirmation.pending.title') }}</h2>
                    <h3 class="text-xl font-bold"></h3>
                    <h3 class="text-base">{{ $t('confirmation.success.description') }}</h3>
                </div>

                <div class="flex items-center justify-center pt-2 rounded-b reload-page">
                    <span class="ml-5 inline-flex rounded-sm shadow-sm">
                        <button @click="reloadPage()"
                                class="inline-flex justify-center items-center mt-3 px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                                {{ $t('confirmation.success.check_status') }}
                        </button>
                    </span>
                </div>
            </div>

            <!-- canceled -->
            <div v-else-if="transaction.attributes.status == 'canceled'">
                <div class="text-center pt-5">
                    <h2 class="text-2xl font-bold mb-3">{{ $t('confirmation.canceled.title') }}</h2>
                    <h3 class="text-lg">{{ $t('confirmation.canceled.subtitle') }}</h3>
                </div>
            </div>

            <!-- failed -->
            <div v-if="transaction.attributes.status == 'failed'">
                <div class="text-center pt-5">
                    <h2 class="text-2xl font-bold">{{ $t('confirmation.failed.title') }}</h2>
                    <h3 class="text-lg">{{ $t('confirmation.failed.subtitle') }}</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// libs
import axios from 'axios';
import * as _api from '../../lib/api';
import * as _log from '../../lib/log';
import * as _state from '../../lib/state';

import VueQrcode from 'vue3-qrcode';
import Ticket from '../../components/Ticket.vue';
import LockerConfirmation from '../../components/ConfirmationLocker.vue'

// set the logger
const logger = _log.get('CONFIRMATION');

export default {
    name: 'Confirmation',
    props: ['location_slug', 'transaction_id'],
    components: {
        Ticket,
        VueQrcode,
        LockerConfirmation,
    },data () {
        return {
            loading: true,
            transaction: false,
        };
    },
    async mounted () {
        axios.defaults.headers.common['Location'] = this.location_slug;

        await this.getTransaction();
    },
    methods: {
        async getTransaction () {
            // get the transaction
            let response = await _api.get('/transactions/' + this.transaction_id);

            logger.log('get transaction', response)();

            this.transaction = response;
            this.loading = false;
        },

        reloadPage () {
            window.location.reload(true);
        },

        orderAgainRedirect () {
            // tableservice
            if (this.transaction.attributes.method == 'tableservice' || this.transaction.attributes.type == 'tablepayment') {
                this.$router.push('/' + this.location_slug + '/tafel/' + this.transaction.attributes.table_id);
            } else if (this.transaction.attributes.method == 'quickorder') {
                this.$router.push('/' + this.location_slug + '/quickorder');
            } else if (this.transaction.attributes.method == 'delivery') {
                this.$router.push('/' + this.location_slug + '/aflevermethode');
            } else if (this.transaction.attributes.method == 'takeaway') {
                this.$router.push('/' + this.location_slug + '/aflevermethode');
            } else if (this.transaction.attributes.method == 'creditwallet') {
                this.$router.push('/' + this.location_slug + '/wallet/opwaarderen');
            } else if (this.transaction.attributes.method == 'elockers') {
                this.$router.push('/' + this.location_slug + '/lockers');
            } else {
                this.$router.push('/');
            }
        },

        tryAgainRedirect () {
            if (this.transaction.attributes.type == 'tablepayment') {
                this.$router.push('/' + this.location_slug + '/tafel/afrekenen');

                return;
            }
            this.$router.push('/' + this.location_slug + '/bestelling/plaatsen');
        },
    },

    computed: {
        confirmation_text () {
            const location = _state.get('location/getLocation');

            return location.confirmation_text.text_confirmation_translations[this.language];
        },

        language() {
            return this.config.localization;
        },

        config () {
            return _state.get('config/getConfig');
        },

        showQrCode() {
            if (this.transaction.attributes.mode === 'qrcode') {
                return true;
            }
            if ((['takeaway']).includes(this.transaction.attributes.method)) {
                return true;
            }
            return true;
        },
        currency (){
            return this.config.settings.currency;
        }
    }
};
</script>
