<template>
    <div class="svg-wrapper locker-instructions-3">
        <!-- Generator: Adobe Illustrator 25.4.8, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg
            version="1.1"
            id="Laag_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 154.05 65.02"
            style="enable-background: new 0 0 154.05 65.02"
            xml:space="preserve"
        >
            <g>
                <g>
                    <path
                        class="st0"
                        d="M143.13,59.22H10.92c-1.89,0-3.42-1.53-3.42-3.42V9.22c0-1.89,1.53-3.42,3.42-3.42h132.2
			c1.89,0,3.42,1.53,3.42,3.42V55.8C146.54,57.69,145.01,59.22,143.13,59.22z"
                    />
                    <path
                        class="st1"
                        d="M143.13,60.98H10.92c-2.86,0-5.18-2.33-5.18-5.18V9.22c0-2.86,2.33-5.18,5.18-5.18h132.21
			c2.86,0,5.18,2.33,5.18,5.18V55.8C148.31,58.66,145.98,60.98,143.13,60.98z M10.92,7.57c-0.91,0-1.65,0.74-1.65,1.65V55.8
			c0,0.91,0.74,1.65,1.65,1.65h132.21c0.91,0,1.65-0.74,1.65-1.65V9.22c0-0.91-0.74-1.65-1.65-1.65H10.92z"
                    />
                </g>
                <g>
                    <circle class="st2" cx="58.46" cy="24.74" r="5.5" />
                    <g>
                        <path
                            class="st1"
                            d="M57.37,24.55l-1.88-2.62h2.05l1,1.53l0.96-1.53h1.94l-1.83,2.62l2.17,3.02h-2.05l-1.23-1.88l-1.32,1.88
				h-2.03L57.37,24.55z"
                        />
                    </g>
                </g>
                <g>
                    <path
                        class="st1"
                        d="M32.23,48.99c-9.09,0-16.48-7.39-16.48-16.48s7.39-16.48,16.48-16.48s16.48,7.39,16.48,16.48
			S41.32,48.99,32.23,48.99z M32.23,19.56c-7.14,0-12.95,5.81-12.95,12.95c0,7.14,5.81,12.95,12.95,12.95
			c7.14,0,12.95-5.81,12.95-12.95C45.18,25.37,39.37,19.56,32.23,19.56z"
                    />
                    <g>
                        <circle class="st1" cx="58.46" cy="40.28" r="5.5" />
                        <circle class="st1" cx="73.13" cy="24.74" r="5.5" />
                        <circle class="st1" cx="73.13" cy="40.28" r="5.5" />
                        <circle class="st1" cx="87.61" cy="24.74" r="5.5" />
                        <circle class="st1" cx="87.61" cy="40.28" r="5.5" />
                        <circle class="st1" cx="102.08" cy="24.74" r="5.5" />
                        <circle class="st1" cx="102.08" cy="40.28" r="5.5" />
                        <circle class="st1" cx="116.56" cy="24.74" r="5.5" />
                        <circle class="st1" cx="116.56" cy="40.28" r="5.5" />
                        <circle class="st1" cx="131.04" cy="24.74" r="5.5" />
                        <circle class="st1" cx="131.04" cy="40.28" r="5.5" />
                    </g>
                </g>

                <rect x="17.51" y="30.75" transform="matrix(0.9999 -0.0149 0.0149 0.9999 -0.4811 0.4841)" class="st1" width="29.43" height="3.53" />
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'LockerInstructions_3',
};
</script>
