<template>
    <div class="svg-wrapper locker-instructions-6">
        <svg
            version="1.1"
            id="Laag_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 154.05 65.02"
            style="enable-background: new 0 0 154.05 65.02"
            xml:space="preserve"
        >
            <g>
                <g>
                    <path
                        class="st0"
                        d="M142.98,59.16H11.07c-1.88,0-3.41-1.53-3.41-3.41V9.28c0-1.88,1.53-3.41,3.41-3.41h131.91
			c1.88,0,3.41,1.53,3.41,3.41v46.47C146.39,57.63,144.86,59.16,142.98,59.16z"
                    />
                    <path
                        class="st1"
                        d="M142.98,60.92H11.07c-2.85,0-5.17-2.32-5.17-5.17V9.28c0-2.85,2.32-5.17,5.17-5.17h131.91
			c2.85,0,5.17,2.32,5.17,5.17v46.47C148.15,58.6,145.83,60.92,142.98,60.92z M11.07,7.63c-0.91,0-1.65,0.74-1.65,1.65v46.47
			c0,0.91,0.74,1.65,1.65,1.65h131.91c0.91,0,1.65-0.74,1.65-1.65V9.28c0-0.91-0.74-1.65-1.65-1.65H11.07z"
                    />
                </g>
                <g>
                    <g>
                        <g>
                            <circle class="st1" cx="58" cy="24.76" r="5.48" />
                        </g>
                        <g>
                            <g>
                                <circle class="st1" cx="58" cy="40.26" r="5.48" />
                                <circle class="st1" cx="72.64" cy="24.76" r="5.48" />
                                <circle class="st1" cx="72.64" cy="40.26" r="5.48" />
                                <circle class="st1" cx="87.08" cy="24.76" r="5.48" />
                                <circle class="st1" cx="87.08" cy="40.26" r="5.48" />
                                <circle class="st1" cx="101.52" cy="24.76" r="5.48" />
                                <circle class="st1" cx="101.52" cy="40.26" r="5.48" />
                                <circle class="st1" cx="115.97" cy="24.76" r="5.48" />
                                <circle class="st1" cx="115.97" cy="40.26" r="5.48" />
                                <circle class="st1" cx="130.41" cy="24.76" r="5.48" />
                                <circle class="st1" cx="130.41" cy="40.26" r="5.48" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <rect
                            x="18.15"
                            y="30.75"
                            transform="matrix(4.651969e-03 -1 1 4.651969e-03 0.1694 65.1946)"
                            class="st2"
                            width="29.37"
                            height="3.52"
                        />
                        <path
                            class="st1"
                            d="M32.83,48.96c-9.07,0-16.45-7.38-16.45-16.44s7.38-16.45,16.45-16.45s16.45,7.38,16.45,16.45
				S41.9,48.96,32.83,48.96z M32.83,19.59c-7.12,0-12.92,5.8-12.92,12.92s5.8,12.92,12.92,12.92s12.92-5.8,12.92-12.92
				S39.96,19.59,32.83,19.59z"
                        />
                    </g>
                </g>
                <path
                    class="st1"
                    d="M31.65,13.53c-0.14,0.11-0.31,0.18-0.5,0.18c-3.95,0-7.81,1.37-10.86,3.87c-2.51,2.05-4.39,4.78-5.41,7.83
		l1.18-0.82c0.36-0.25,0.85-0.16,1.1,0.2c0.24,0.34,0.17,0.8-0.15,1.06c-0.02,0.01-0.03,0.03-0.05,0.04L14,27.95c0,0,0,0,0,0
		c-0.17,0.12-0.39,0.17-0.61,0.13c-0.23-0.05-0.42-0.19-0.52-0.38l-2.01-2.9c-0.25-0.36-0.16-0.85,0.2-1.1
		c0.36-0.25,0.85-0.16,1.1,0.2l1.06,1.52c1.08-3.54,3.19-6.71,6.07-9.06c3.34-2.73,7.56-4.23,11.87-4.23c0.44,0,0.79,0.36,0.79,0.79
		C31.94,13.17,31.83,13.39,31.65,13.53z"
                />
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'LockerInstructions_6',
};
</script>
