<template>
    <div class="svg-wrapper locker-instructions-2">
        <svg
            version="1.1"
            id="Laag_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 154.05 65.02"
            style="enable-background: new 0 0 154.05 65.02"
            xml:space="preserve"
            :class="{'real-locker-number': locker_number}"
        >
            <g>
                <g>
                    <defs>
                        <path
                            id="SVGID_00000183947503349039306130000004874295584551042463_"
                            d="M143.13,59.22H10.92c-1.89,0-3.42-1.53-3.42-3.42V9.22
				c0-1.89,1.53-3.42,3.42-3.42h132.2c1.89,0,3.42,1.53,3.42,3.42V55.8C146.54,57.69,145.01,59.22,143.13,59.22z"
                        />
                    </defs>
                    <clipPath id="SVGID_00000020374152590968471220000001870691772702717352_">
                        <use xlink:href="#SVGID_00000183947503349039306130000004874295584551042463_" style="overflow: visible" />
                    </clipPath>
                    <g style="clip-path: url(#SVGID_00000020374152590968471220000001870691772702717352_)">
                        <g>
                            <rect x="28.2" y="46.28" class="st0" width="31.73" height="31.73" />
                            <g>
                                <g>
                                    <path
                                        class="st1"
                                        d="M59.25,79.09H28.87c-0.97,0-1.76-0.79-1.76-1.76V46.95c0-0.97,0.79-1.76,1.76-1.76h30.38
							c0.97,0,1.76,0.79,1.76,1.76v30.38C61,78.3,60.22,79.09,59.25,79.09z M28.87,46.28c-0.37,0-0.68,0.3-0.68,0.68v30.38
							c0,0.37,0.3,0.68,0.68,0.68h30.38c0.37,0,0.68-0.3,0.68-0.68V46.95c0-0.37-0.3-0.68-0.68-0.68H28.87z"
                                    />
                                    <path
                                        class="st5"
                                        d="M31.27,65.81c-0.95,0-1.73-0.77-1.73-1.73V60.2c0-0.95,0.77-1.73,1.73-1.73S33,59.25,33,60.2v3.89
							C33,65.04,32.23,65.81,31.27,65.81z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <rect x="61" y="46.28" class="st0" width="31.73" height="31.73" />
                            <g>
                                <g>
                                    <path
                                        class="st1"
                                        d="M92.06,79.09H61.68c-0.97,0-1.76-0.79-1.76-1.76V46.95c0-0.97,0.79-1.76,1.76-1.76h30.38
							c0.97,0,1.76,0.79,1.76,1.76v30.38C93.81,78.3,93.02,79.09,92.06,79.09z M61.68,46.28c-0.37,0-0.68,0.3-0.68,0.68v30.38
							c0,0.37,0.3,0.68,0.68,0.68h30.38c0.37,0,0.68-0.3,0.68-0.68V46.95c0-0.37-0.3-0.68-0.68-0.68H61.68z"
                                    />
                                    <path
                                        class="st5"
                                        d="M64.08,65.81c-0.95,0-1.73-0.77-1.73-1.73V60.2c0-0.95,0.77-1.73,1.73-1.73c0.95,0,1.73,0.77,1.73,1.73
							v3.89C65.81,65.04,65.03,65.81,64.08,65.81z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <rect x="94.12" y="46.28" class="st0" width="31.73" height="31.73" />
                            <g>
                                <g>
                                    <path
                                        class="st1"
                                        d="M125.18,79.09H94.8c-0.97,0-1.76-0.79-1.76-1.76V46.95c0-0.97,0.79-1.76,1.76-1.76h30.38
							c0.97,0,1.76,0.79,1.76,1.76v30.38C126.93,78.3,126.14,79.09,125.18,79.09z M94.8,46.28c-0.37,0-0.68,0.3-0.68,0.68v30.38
							c0,0.37,0.3,0.68,0.68,0.68h30.38c0.37,0,0.68-0.3,0.68-0.68V46.95c0-0.37-0.3-0.68-0.68-0.68H94.8z"
                                    />
                                    <path
                                        class="st5"
                                        d="M97.2,65.81c-0.95,0-1.73-0.77-1.73-1.73V60.2c0-0.95,0.77-1.73,1.73-1.73s1.73,0.77,1.73,1.73v3.89
							C98.93,65.04,98.15,65.81,97.2,65.81z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <rect x="28.2" y="-18.57" class="st0" width="31.73" height="31.73" />
                            <g>
                                <g>
                                    <path
                                        class="st1"
                                        d="M59.25,14.24H28.87c-0.97,0-1.76-0.79-1.76-1.76v-30.38c0-0.97,0.79-1.76,1.76-1.76h30.38
							c0.97,0,1.76,0.79,1.76,1.76v30.38C61,13.45,60.22,14.24,59.25,14.24z M28.87-18.57c-0.37,0-0.68,0.3-0.68,0.68v30.38
							c0,0.37,0.3,0.68,0.68,0.68h30.38c0.37,0,0.68-0.3,0.68-0.68v-30.38c0-0.37-0.3-0.68-0.68-0.68H28.87z"
                                    />
                                    <path
                                        class="st5"
                                        d="M31.27,0.96c-0.95,0-1.73-0.77-1.73-1.73v-3.89c0-0.95,0.77-1.73,1.73-1.73S33-5.6,33-4.65v3.89
							C33,0.19,32.23,0.96,31.27,0.96z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <rect x="61" y="-18.57" class="st0" width="31.73" height="31.73" />
                            <g>
                                <g>
                                    <path
                                        class="st1"
                                        d="M92.06,14.24H61.68c-0.97,0-1.76-0.79-1.76-1.76v-30.38c0-0.97,0.79-1.76,1.76-1.76h30.38
							c0.97,0,1.76,0.79,1.76,1.76v30.38C93.81,13.45,93.02,14.24,92.06,14.24z M61.68-18.57c-0.37,0-0.68,0.3-0.68,0.68v30.38
							c0,0.37,0.3,0.68,0.68,0.68h30.38c0.37,0,0.68-0.3,0.68-0.68v-30.38c0-0.37-0.3-0.68-0.68-0.68H61.68z"
                                    />
                                    <path
                                        class="st5"
                                        d="M64.08,0.96c-0.95,0-1.73-0.77-1.73-1.73v-3.89c0-0.95,0.77-1.73,1.73-1.73c0.95,0,1.73,0.77,1.73,1.73
							v3.89C65.81,0.19,65.03,0.96,64.08,0.96z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <rect x="94.12" y="-18.57" class="st0" width="31.73" height="31.73" />
                            <g>
                                <g>
                                    <path
                                        class="st1"
                                        d="M125.18,14.24H94.8c-0.97,0-1.76-0.79-1.76-1.76v-30.38c0-0.97,0.79-1.76,1.76-1.76h30.38
							c0.97,0,1.76,0.79,1.76,1.76v30.38C126.93,13.45,126.14,14.24,125.18,14.24z M94.8-18.57c-0.37,0-0.68,0.3-0.68,0.68v30.38
							c0,0.37,0.3,0.68,0.68,0.68h30.38c0.37,0,0.68-0.3,0.68-0.68v-30.38c0-0.37-0.3-0.68-0.68-0.68H94.8z"
                                    />
                                    <path
                                        class="st5"
                                        d="M97.2,0.96c-0.95,0-1.73-0.77-1.73-1.73v-3.89c0-0.95,0.77-1.73,1.73-1.73s1.73,0.77,1.73,1.73v3.89
							C98.93,0.19,98.15,0.96,97.2,0.96z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <rect x="61" y="14.24" class="st2" width="31.73" height="31.73" />
                            <g>
                                <g>
                                    <path
                                        class="st1"
                                        d="M92.06,47.05H61.68c-0.97,0-1.76-0.79-1.76-1.76V14.91c0-0.97,0.79-1.76,1.76-1.76h30.38
							c0.97,0,1.76,0.79,1.76,1.76v30.38C93.81,46.26,93.02,47.05,92.06,47.05z M61.68,14.24c-0.37,0-0.68,0.3-0.68,0.68v30.38
							c0,0.37,0.3,0.68,0.68,0.68h30.38c0.37,0,0.68-0.3,0.68-0.68V14.91c0-0.37-0.3-0.68-0.68-0.68H61.68z"
                                    />
                                    <path
                                        class="st6"
                                        d="M64.08,33.77c-0.95,0-1.73-0.77-1.73-1.73v-3.89c0-0.95,0.77-1.73,1.73-1.73c0.95,0,1.73,0.77,1.73,1.73
							v3.89C65.81,33,65.03,33.77,64.08,33.77z"
                                    />
                                </g>
                            </g>
                        </g>
                        <rect x="64.74" y="16.46" class="st0" width="24.65" height="9.42" />
                        <path
                            class="st0"
                            d="M89.69,51.02c0,0.3-0.23,0.55-0.51,0.55H78.06H65.1c-0.28,0-0.51-0.25-0.51-0.55v-1.49V37.16
				c0-0.3,0.23-0.55,0.51-0.55h11.11h12.96c0.28,0,0.51,0.25,0.51,0.55v1.49V51.02z"
                        />
                        <rect x="65.81" y="17.48" class="st7" width="23.59" height="8.4" />
                        <text transform="matrix(1 0 0 1 66.0937 24.8286)" class="st1 st8 st9">
                            {{processed_locker_number}}
                        </text>
                        <g>
                            <rect x="28.2" y="14.24" class="st0" width="31.73" height="31.73" />
                            <g>
                                <g>
                                    <path
                                        class="st1"
                                        d="M59.25,47.05H28.87c-0.97,0-1.76-0.79-1.76-1.76V14.91c0-0.97,0.79-1.76,1.76-1.76h30.38
							c0.97,0,1.76,0.79,1.76,1.76v30.38C61,46.26,60.22,47.05,59.25,47.05z M28.87,14.24c-0.37,0-0.68,0.3-0.68,0.68v30.38
							c0,0.37,0.3,0.68,0.68,0.68h30.38c0.37,0,0.68-0.3,0.68-0.68V14.91c0-0.37-0.3-0.68-0.68-0.68H28.87z"
                                    />
                                    <path
                                        class="st5"
                                        d="M31.27,33.77c-0.95,0-1.73-0.77-1.73-1.73v-3.89c0-0.95,0.77-1.73,1.73-1.73S33,27.21,33,28.16v3.89
							C33,33,32.23,33.77,31.27,33.77z"
                                    />
                                </g>
                            </g>
                        </g>
                        <rect x="31.94" y="16.46" class="st1" width="24.65" height="9.42" />
                        <rect x="33" y="17.48" class="st7" width="23.59" height="8.4" />
                        <text transform="matrix(1 0 0 1 33.2871 24.8286)" class="st0 st8 st9">{{neighbouringLockerNumber(-1)}}</text>
                        <g>
                            <rect x="94.12" y="14.24" class="st0" width="31.73" height="31.73" />
                            <g>
                                <g>
                                    <path
                                        class="st1"
                                        d="M125.18,47.05H94.8c-0.97,0-1.76-0.79-1.76-1.76V14.91c0-0.97,0.79-1.76,1.76-1.76h30.38
							c0.97,0,1.76,0.79,1.76,1.76v30.38C126.93,46.26,126.14,47.05,125.18,47.05z M94.8,14.24c-0.37,0-0.68,0.3-0.68,0.68v30.38
							c0,0.37,0.3,0.68,0.68,0.68h30.38c0.37,0,0.68-0.3,0.68-0.68V14.91c0-0.37-0.3-0.68-0.68-0.68H94.8z"
                                    />
                                    <path
                                        class="st5"
                                        d="M97.2,33.77c-0.95,0-1.73-0.77-1.73-1.73v-3.89c0-0.95,0.77-1.73,1.73-1.73s1.73,0.77,1.73,1.73v3.89
							C98.93,33,98.15,33.77,97.2,33.77z"
                                    />
                                </g>
                            </g>
                        </g>
                        <rect x="97.86" y="16.46" class="st1" width="24.65" height="9.42" />
                        <rect x="98.93" y="17.48" class="st7" width="23.59" height="8.4" />
                        <text transform="matrix(1 0 0 1 99.2129 24.8286)" class="st0 st8 st9">{{neighbouringLockerNumber(+1)}}</text>
                        <g>
                            <rect x="126.93" y="46.28" class="st0" width="31.73" height="31.73" />
                            <g>
                                <g>
                                    <path
                                        class="st1"
                                        d="M157.98,79.09h-30.38c-0.97,0-1.76-0.79-1.76-1.76V46.95c0-0.97,0.79-1.76,1.76-1.76h30.38
							c0.97,0,1.76,0.79,1.76,1.76v30.38C159.74,78.3,158.95,79.09,157.98,79.09z M127.61,46.28c-0.37,0-0.68,0.3-0.68,0.68v30.38
							c0,0.37,0.3,0.68,0.68,0.68h30.38c0.37,0,0.68-0.3,0.68-0.68V46.95c0-0.37-0.3-0.68-0.68-0.68H127.61z"
                                    />
                                    <path
                                        class="st5"
                                        d="M130.01,65.81c-0.95,0-1.73-0.77-1.73-1.73V60.2c0-0.95,0.77-1.73,1.73-1.73c0.95,0,1.73,0.77,1.73,1.73
							v3.89C131.73,65.04,130.96,65.81,130.01,65.81z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <rect x="126.93" y="-18.57" class="st0" width="31.73" height="31.73" />
                            <g>
                                <g>
                                    <path
                                        class="st1"
                                        d="M157.98,14.24h-30.38c-0.97,0-1.76-0.79-1.76-1.76v-30.38c0-0.97,0.79-1.76,1.76-1.76h30.38
							c0.97,0,1.76,0.79,1.76,1.76v30.38C159.74,13.45,158.95,14.24,157.98,14.24z M127.61-18.57c-0.37,0-0.68,0.3-0.68,0.68v30.38
							c0,0.37,0.3,0.68,0.68,0.68h30.38c0.37,0,0.68-0.3,0.68-0.68v-30.38c0-0.37-0.3-0.68-0.68-0.68H127.61z"
                                    />
                                    <path
                                        class="st5"
                                        d="M130.01,0.96c-0.95,0-1.73-0.77-1.73-1.73v-3.89c0-0.95,0.77-1.73,1.73-1.73c0.95,0,1.73,0.77,1.73,1.73
							v3.89C131.73,0.19,130.96,0.96,130.01,0.96z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <rect x="126.93" y="14.24" class="st0" width="31.73" height="31.73" />
                            <g>
                                <g>
                                    <path
                                        class="st1"
                                        d="M157.98,47.05h-30.38c-0.97,0-1.76-0.79-1.76-1.76V14.91c0-0.97,0.79-1.76,1.76-1.76h30.38
							c0.97,0,1.76,0.79,1.76,1.76v30.38C159.74,46.26,158.95,47.05,157.98,47.05z M127.61,14.24c-0.37,0-0.68,0.3-0.68,0.68v30.38
							c0,0.37,0.3,0.68,0.68,0.68h30.38c0.37,0,0.68-0.3,0.68-0.68V14.91c0-0.37-0.3-0.68-0.68-0.68H127.61z"
                                    />
                                    <path
                                        class="st5"
                                        d="M130.01,33.77c-0.95,0-1.73-0.77-1.73-1.73v-3.89c0-0.95,0.77-1.73,1.73-1.73c0.95,0,1.73,0.77,1.73,1.73
							v3.89C131.73,33,130.96,33.77,130.01,33.77z"
                                    />
                                </g>
                            </g>
                        </g>
                        <rect x="130.67" y="16.46" class="st1" width="24.65" height="9.42" />
                        <rect x="131.73" y="17.48" class="st7" width="23.59" height="8.4" />
                        <text transform="matrix(1 0 0 1 132.0202 24.8286)" class="st0 st8 st9">{{neighbouringLockerNumber(+2)}}</text>
                        <g>
                            <rect x="-4.61" y="46.28" class="st0" width="31.73" height="31.73" />
                            <g>
                                <g>
                                    <path
                                        class="st1"
                                        d="M26.44,79.09H-3.94c-0.97,0-1.76-0.79-1.76-1.76V46.95c0-0.97,0.79-1.76,1.76-1.76h30.38
							c0.97,0,1.76,0.79,1.76,1.76v30.38C28.2,78.3,27.41,79.09,26.44,79.09z M-3.94,46.28c-0.37,0-0.68,0.3-0.68,0.68v30.38
							c0,0.37,0.3,0.68,0.68,0.68h30.38c0.37,0,0.68-0.3,0.68-0.68V46.95c0-0.37-0.3-0.68-0.68-0.68H-3.94z"
                                    />
                                    <path
                                        class="st5"
                                        d="M-1.53,65.81c-0.95,0-1.73-0.77-1.73-1.73V60.2c0-0.95,0.77-1.73,1.73-1.73s1.73,0.77,1.73,1.73v3.89
							C0.19,65.04-0.58,65.81-1.53,65.81z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <rect x="-4.61" y="-18.57" class="st0" width="31.73" height="31.73" />
                            <g>
                                <g>
                                    <path
                                        class="st1"
                                        d="M26.44,14.24H-3.94c-0.97,0-1.76-0.79-1.76-1.76v-30.38c0-0.97,0.79-1.76,1.76-1.76h30.38
							c0.97,0,1.76,0.79,1.76,1.76v30.38C28.2,13.45,27.41,14.24,26.44,14.24z M-3.94-18.57c-0.37,0-0.68,0.3-0.68,0.68v30.38
							c0,0.37,0.3,0.68,0.68,0.68h30.38c0.37,0,0.68-0.3,0.68-0.68v-30.38c0-0.37-0.3-0.68-0.68-0.68H-3.94z"
                                    />
                                    <path
                                        class="st5"
                                        d="M-1.53,0.96c-0.95,0-1.73-0.77-1.73-1.73v-3.89c0-0.95,0.77-1.73,1.73-1.73S0.19-5.6,0.19-4.65v3.89
							C0.19,0.19-0.58,0.96-1.53,0.96z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g>
                            <rect x="-4.61" y="14.24" class="st0" width="31.73" height="31.73" />
                            <g>
                                <g>
                                    <path
                                        class="st1"
                                        d="M26.44,47.05H-3.94c-0.97,0-1.76-0.79-1.76-1.76V14.91c0-0.97,0.79-1.76,1.76-1.76h30.38
							c0.97,0,1.76,0.79,1.76,1.76v30.38C28.2,46.26,27.41,47.05,26.44,47.05z M-3.94,14.24c-0.37,0-0.68,0.3-0.68,0.68v30.38
							c0,0.37,0.3,0.68,0.68,0.68h30.38c0.37,0,0.68-0.3,0.68-0.68V14.91c0-0.37-0.3-0.68-0.68-0.68H-3.94z"
                                    />
                                    <path
                                        class="st5"
                                        d="M-1.53,33.77c-0.95,0-1.73-0.77-1.73-1.73v-3.89c0-0.95,0.77-1.73,1.73-1.73s1.73,0.77,1.73,1.73v3.89
							C0.19,33-0.58,33.77-1.53,33.77z"
                                    />
                                </g>
                            </g>
                        </g>
                        <rect x="-0.87" y="16.46" class="st1" width="24.65" height="9.42" />
                        <rect x="0.19" y="17.48" class="st7" width="23.59" height="8.4" />
                        <text transform="matrix(1 0 0 1 0.4797 24.8286)" class="st0 st8 st9">{{neighbouringLockerNumber(-2)}}</text>
                        <rect x="65.81" y="40.16" class="st7" width="23.59" height="8.4" />
                        <text transform="matrix(1 0 0 1 66.0937 47.5093)" class="st1 st8 st9">{{processed_locker_number}}</text>
                        <path
                            class="st10"
                            d="M89.18,36.25H76.21H65.1c-0.74,0-1.34,0.6-1.34,1.34v11.57v1.39c0,0.74,0.6,1.34,1.34,1.34h12.96h11.11
				c0.74,0,1.34-0.6,1.34-1.34V38.97v-1.39C90.51,36.85,89.91,36.25,89.18,36.25z M89.69,50.55c0,0.28-0.23,0.51-0.51,0.51H78.06
				H65.1c-0.28,0-0.51-0.23-0.51-0.51v-1.39V37.58c0-0.28,0.23-0.51,0.51-0.51h11.11h12.96c0.28,0,0.51,0.23,0.51,0.51v1.39V50.55z"
                        />
                    </g>
                </g>
                <path
                    class="st11"
                    d="M143.13,59.22H10.92c-1.89,0-3.42-1.53-3.42-3.42V9.22c0-1.89,1.53-3.42,3.42-3.42h132.2
		c1.89,0,3.42,1.53,3.42,3.42V55.8C146.54,57.69,145.01,59.22,143.13,59.22z"
                />
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'LockerInstructions_2',
    props: ['locker_number'],
    data() {
        return {
            processed_locker_number: '0003',
            number_size: 4,
        }
    },
    mounted() {
        if (this.locker_number) {
            this.processed_locker_number = this.locker_number;
            this.number_size = 5;
        }
    },
    methods: {
        neighbouringLockerNumber(amount) {
            let newAmount = parseInt(this.processed_locker_number) + amount;

            return this.pad(newAmount, this.number_size);
        },
        pad(num, size) {
            var s = "000000000" + num;
            return s.substr(s.length - size);
        }
    }
};
</script>
