<template>
    <div class="svg-wrapper locker-instructions-4">
        <svg
            version="1.1"
            id="Laag_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 154.05 65.02"
            style="enable-background: new 0 0 154.05 65.02"
            xml:space="preserve"
        >
            <g>
                <g>
                    <path
                        class="st0"
                        d="M143.13,59.22H10.92c-1.89,0-3.42-1.53-3.42-3.42V9.22c0-1.89,1.53-3.42,3.42-3.42h132.2
			c1.89,0,3.42,1.53,3.42,3.42V55.8C146.54,57.69,145.01,59.22,143.13,59.22z"
                    />
                    <path
                        class="st1"
                        d="M143.13,60.98H10.92c-2.86,0-5.18-2.33-5.18-5.18V9.23c0-2.86,2.33-5.18,5.18-5.18h132.21
			c2.86,0,5.18,2.33,5.18,5.18V55.8C148.31,58.66,145.98,60.98,143.13,60.98z M10.92,7.57c-0.91,0-1.65,0.74-1.65,1.65V55.8
			c0,0.91,0.74,1.65,1.65,1.65h132.21c0.91,0,1.65-0.74,1.65-1.65V9.23c0-0.91-0.74-1.65-1.65-1.65H10.92z"
                    />
                </g>
                <g>
                    <g>
                        <g>
                            <circle class="st1" cx="57.95" cy="24.75" r="5.5" />
                        </g>
                        <g>
                            <g>
                                <circle class="st2" cx="57.95" cy="40.28" r="5.5" />
                                <circle class="st2" cx="72.63" cy="24.75" r="5.5" />
                                <circle class="st1" cx="72.63" cy="40.28" r="5.5" />
                                <circle class="st1" cx="87.1" cy="24.75" r="5.5" />
                                <circle class="st1" cx="87.1" cy="40.28" r="5.5" />
                                <circle class="st1" cx="101.58" cy="24.75" r="5.5" />
                                <circle class="st2" cx="101.58" cy="40.28" r="5.5" />
                                <circle class="st1" cx="116.06" cy="24.75" r="5.5" />
                                <circle class="st2" cx="116.06" cy="40.28" r="5.5" />
                                <circle class="st1" cx="130.53" cy="24.75" r="5.5" />
                                <circle class="st1" cx="130.53" cy="40.28" r="5.5" />
                            </g>
                        </g>
                        <g>
                            <path
                                class="st1 hidden"
                                d="M70.01,26.51l2.91-2.62c0.15-0.13,0.3-0.29,0.44-0.46c0.15-0.17,0.22-0.37,0.22-0.59
					c0-0.25-0.09-0.45-0.28-0.6c-0.18-0.14-0.4-0.22-0.65-0.22c-0.3,0-0.53,0.09-0.69,0.28c-0.17,0.18-0.26,0.41-0.28,0.68
					l-1.59-0.12c0.02-0.39,0.1-0.72,0.24-1.01c0.14-0.29,0.33-0.52,0.56-0.72c0.23-0.19,0.51-0.33,0.82-0.43
					c0.31-0.1,0.65-0.14,1.02-0.14c0.34,0,0.66,0.05,0.95,0.14c0.3,0.1,0.55,0.24,0.77,0.42c0.22,0.19,0.39,0.42,0.51,0.7
					c0.12,0.28,0.18,0.61,0.18,0.99c0,0.24-0.02,0.46-0.07,0.65c-0.05,0.19-0.12,0.37-0.21,0.53c-0.09,0.16-0.19,0.31-0.31,0.44
					c-0.12,0.13-0.25,0.27-0.39,0.39l-2.28,1.96h3.33v1.4h-5.23V26.51z"
                            />
                        </g>
                        <g>
                            <path
                                class="st1 hidden"
                                d="M57.86,38.91c0.08-0.02,0.17-0.04,0.25-0.05c0.08-0.01,0.17-0.02,0.27-0.02c0.35,0,0.67,0.06,0.96,0.19
					c0.29,0.13,0.54,0.3,0.74,0.52c0.2,0.22,0.36,0.48,0.48,0.78c0.11,0.3,0.17,0.62,0.17,0.96c0,0.39-0.07,0.74-0.22,1.05
					c-0.14,0.31-0.34,0.59-0.59,0.81c-0.25,0.23-0.55,0.4-0.89,0.52c-0.34,0.12-0.71,0.18-1.1,0.18c-0.39,0-0.76-0.06-1.1-0.18
					c-0.34-0.12-0.63-0.3-0.88-0.52c-0.25-0.23-0.45-0.5-0.59-0.81c-0.14-0.31-0.22-0.66-0.22-1.05c0-0.27,0.03-0.51,0.07-0.74
					c0.05-0.22,0.11-0.43,0.2-0.63c0.08-0.2,0.18-0.39,0.29-0.57c0.11-0.18,0.23-0.37,0.34-0.56l1.64-2.56h1.94L57.86,38.91z
					M59.14,41.22c0-0.34-0.11-0.62-0.33-0.84c-0.22-0.22-0.51-0.33-0.87-0.33c-0.36,0-0.65,0.11-0.87,0.33
					c-0.22,0.22-0.33,0.5-0.33,0.84c0,0.34,0.11,0.62,0.33,0.84c0.22,0.22,0.51,0.33,0.87,0.33c0.36,0,0.65-0.11,0.87-0.33
					C59.03,41.84,59.14,41.56,59.14,41.22z"
                            />
                        </g>
                        <g>
                            <path
                                class="st1 hidden"
                                d="M101.67,41.56c-0.08,0.02-0.17,0.04-0.25,0.05c-0.08,0.01-0.17,0.02-0.27,0.02c-0.35,0-0.67-0.06-0.96-0.19
					c-0.29-0.13-0.54-0.3-0.74-0.52c-0.2-0.22-0.36-0.48-0.48-0.78c-0.11-0.3-0.17-0.62-0.17-0.96c0-0.39,0.07-0.74,0.22-1.05
					s0.34-0.58,0.59-0.81c0.25-0.23,0.55-0.4,0.89-0.52c0.34-0.12,0.7-0.19,1.1-0.19c0.39,0,0.75,0.06,1.1,0.19s0.64,0.3,0.89,0.52
					c0.25,0.23,0.45,0.5,0.59,0.81s0.22,0.67,0.22,1.05c0,0.27-0.03,0.51-0.07,0.74c-0.05,0.22-0.11,0.43-0.2,0.63
					c-0.08,0.2-0.18,0.39-0.29,0.57c-0.11,0.18-0.23,0.37-0.34,0.56l-1.64,2.56h-1.94L101.67,41.56z M100.39,39.25
					c0,0.34,0.11,0.62,0.33,0.84c0.22,0.22,0.51,0.33,0.87,0.33c0.36,0,0.65-0.11,0.87-0.33c0.22-0.22,0.33-0.5,0.33-0.84
					c0-0.34-0.11-0.62-0.33-0.84c-0.22-0.22-0.51-0.33-0.87-0.33c-0.36,0-0.65,0.11-0.87,0.33
					C100.5,38.63,100.39,38.91,100.39,39.25z"
                            />
                        </g>
                        <g>
                            <path
                                class="st1 hidden"
                                d="M113.27,40.28c0-0.44,0.04-0.9,0.12-1.35c0.08-0.46,0.23-0.88,0.44-1.25c0.21-0.37,0.49-0.68,0.85-0.92
					c0.36-0.24,0.82-0.35,1.38-0.35c0.56,0,1.02,0.12,1.38,0.35c0.36,0.24,0.65,0.54,0.85,0.92c0.21,0.37,0.35,0.79,0.44,1.25
					c0.08,0.46,0.12,0.91,0.12,1.35c0,0.45-0.04,0.9-0.12,1.36c-0.08,0.46-0.23,0.87-0.44,1.25c-0.21,0.38-0.49,0.68-0.85,0.92
					c-0.36,0.24-0.82,0.35-1.38,0.35c-0.56,0-1.02-0.12-1.38-0.35c-0.36-0.24-0.65-0.54-0.85-0.92c-0.21-0.37-0.35-0.79-0.44-1.25
					C113.31,41.18,113.27,40.73,113.27,40.28z M114.86,40.28c0,0.2,0.01,0.44,0.03,0.71c0.02,0.28,0.07,0.54,0.14,0.79
					c0.08,0.25,0.2,0.47,0.35,0.65c0.16,0.18,0.38,0.27,0.67,0.27c0.28,0,0.5-0.09,0.67-0.27s0.28-0.39,0.36-0.65
					c0.08-0.25,0.13-0.52,0.14-0.79c0.02-0.27,0.03-0.51,0.03-0.71c0-0.2-0.01-0.43-0.03-0.71c-0.02-0.28-0.07-0.54-0.14-0.79
					c-0.08-0.25-0.2-0.47-0.36-0.65c-0.16-0.18-0.38-0.27-0.67-0.27c-0.29,0-0.51,0.09-0.67,0.27c-0.16,0.18-0.28,0.39-0.35,0.65
					c-0.08,0.25-0.13,0.52-0.14,0.79S114.86,40.08,114.86,40.28z"
                            />
                        </g>
                    </g>
                    <g>
                        <rect
                            x="18.02"
                            y="30.75"
                            transform="matrix(1 -1.606636e-03 1.606636e-03 1 -0.0522 0.0526)"
                            class="st2"
                            width="29.43"
                            height="3.53"
                        />
                        <path
                            class="st1"
                            d="M32.73,48.99c-9.09,0-16.48-7.39-16.48-16.48s7.39-16.48,16.48-16.48s16.48,7.39,16.48,16.48
				S41.82,48.99,32.73,48.99z M32.73,19.56c-7.14,0-12.95,5.81-12.95,12.95c0,7.14,5.81,12.95,12.95,12.95s12.95-5.81,12.95-12.95
				C45.69,25.37,39.88,19.56,32.73,19.56z"
                        />
                    </g>
                </g>
                <path
                    class="st1"
                    d="M13.96,27.61c0.14-0.11,0.24-0.27,0.27-0.46c0.76-3.88,2.87-7.41,5.92-9.93c2.51-2.07,5.56-3.38,8.76-3.79
		l-1.03,1c-0.31,0.3-0.32,0.81-0.02,1.12c0.29,0.3,0.76,0.32,1.07,0.06c0.02-0.01,0.03-0.03,0.05-0.04l2.59-2.5c0,0,0,0,0,0
		c0.15-0.15,0.25-0.35,0.25-0.58c0-0.24-0.11-0.45-0.27-0.59l-2.46-2.54c-0.3-0.32-0.81-0.32-1.12-0.02
		c-0.31,0.3-0.32,0.81-0.02,1.12l1.29,1.33c-3.69,0.37-7.21,1.83-10.09,4.2c-3.34,2.75-5.63,6.61-6.47,10.85
		c-0.08,0.43,0.2,0.85,0.63,0.93C13.54,27.82,13.78,27.75,13.96,27.61z"
                />
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'LockerInstructions_4',
};
</script>
